import { Cell as ExcelCell } from 'exceljs';
import { Row, Column as GridColumn } from '@devexpress/dx-react-grid';
import { Column, ExportSummary } from '@devexpress/dx-react-grid-export';
import { Search } from '@mui/icons-material';

export const renderSearchButton = (isMobile: boolean) => {
  return isMobile ? <Search /> : 'View';
};

export function renderSearchIcon(isMobile: boolean) {
    if (isMobile) return <Search />;
    return null;
}

export function renderSearchButtonText(isMobile: boolean) {
    if (!isMobile) return 'View';
    return null;
}

export const customizeDateColumns = (
  cell: ExcelCell,
  row: Row,
  column: GridColumn,
  dateColumnsName: string[]
) => {
  if (dateColumnsName?.includes(column.name)) {
    if (row[column.name] !== '') {
      cell.value = new Date(row[column.name]);
      cell.numFmt = 'dd/mm/yy h:mm:ss';
    }
  }
};

export const customizeCountSummaryCell = (
  cells: ExcelCell,
  row: Column,
  summary: ExportSummary
) => {
  if (cells.formula) {
    if (cells.formula?.includes('COUNT')) {
      const cellsCount = summary.ranges[0][1] - summary.ranges[0][0] + 1;
      cells.value = `Total Messages: ${cellsCount}`;
    } else {
      cells.value = '';
    }
  }
};