import { Column } from '@devexpress/dx-react-grid';

export const COMBINED_BILLING_REPORT_TITLE =
  'Combined NPS, SMS and Email Billing Report';

export const COMBINED_BILLING_REPORT_MESSAGE =
  'Combined billing report for NPS, SMS and Email for the given date range - (PPG ONLY)';

export const COMBINED_BILLING_REPORT_GRID_TITLE = 'CombinedNpsSmsEmailReportPPG';

export const dateColumnsName = ['startDate', 'endDate'];

export const columns: Column[] = [ 
  { name: 'repairerName', title: 'Repairer Name' },
  { name: 'repairerGroup', title: 'Repairer Group' },
  { name: 'schemeCode', title: 'Scheme Code' },
  { name: 'workProvider', title: 'Work Provider' },
  { name: 'billingCategory', title: 'Billing Category' },
  { name: 'billingCount', title: 'Billing Count' },
  { name: 'siteCode', title: 'Site Code' },
  { name: 'startDate', title: 'Start Date' },
  { name: 'endDate', title: 'End Date' },
];

export const summaryColumns: Column[] = [
  { name: 'workProvider', title: 'Work Provider' },
  { name: 'npsBillingCount', title: 'NPS' },
  { name: 'smsBillingCount', title: 'SMS' },
  { name: 'emailBillingCount', title: 'Email' },
]