import {
    DataTypeProvider,
    DataTypeProviderProps,
  } from '@devexpress/dx-react-grid';
  import { formatDate } from 'utils/helpers';
  
  type DateFormatterProps = DataTypeProvider.ValueFormatterProps;
  
  const DateFormatter = ({ value }: DateFormatterProps) => {
    return <div>{formatDate(value)}</div>;
  };
  
  export const DateOnlyFormatter: React.ComponentType<DataTypeProviderProps> = (
    props: DataTypeProviderProps
  ) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;