import Paper from '@mui/material/Paper';
import {
  Column,
  Filter,
  Sorting,
  Grouping,
  ChangeSet,
  PagingState,
  EditingState,
  SortingState,
  SummaryState,
  GroupingState,
  FilteringState,
  SelectionState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
  IntegratedGrouping,
  IntegratedFiltering,
  IntegratedSelection,
  TableBandHeader as TableBandHeaderBase,
  Table as TableBase,
  TableGroupRow as TableGroupRowBase,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  ExportPanel,
  PagingPanel,
  GroupingPanel,
  TableGroupRow,
  TableSelection,
  TableFilterRow,
  TableHeaderRow,
  TableEditColumn,
  TableSummaryRow,
  DragDropProvider,
  TableBandHeader,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { PrimaryButton } from 'components/Button';
import { Workbook, Worksheet } from 'exceljs';
import { logger } from 'core/logger';
import styled from '@emotion/styled';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { Container } from 'components/Layout';
import Skeleton from 'components/Skeleton';
import {  ExportRef, Props } from './types';
import { appInsights } from 'core/logger/ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { base64HeaderImage, customMessages, rootContainerId } from './constants';
import { onPDFExport } from './helpers/onPDFExport';
import { ExportMenu } from './components/ExportMenu';
import { exportToCSV } from './helpers/exportToCSV';

const TableComponent = (
  props: JSX.IntrinsicAttributes &
    Record<string, unknown> & {
      [x: string]: unknown;
      className?: string | undefined;
      style?: CSSProperties | undefined;
    } & { children?: ReactNode }
) => {
  return <Table.Table {...props} />;
};

const TableRowCellComponent = (props: TableHeaderRow.CellProps) => {
  return <TableHeaderRowCell {...props} />;
};
const CellComponent = (props: TableBase.DataCellProps) => {
  return <Table.Cell {...props} />;
};

const ToolbarRoot = (props: Toolbar.RootProps) => (
  <CustomToolbarRoot {...props} />
);

const TableHeaderContent = ({
  column,
  children,
  ...restProps
}: TableHeaderRow.ContentProps) => (
  <TableHeaderRow.Content column={column} {...restProps}>
    <b>{children}</b>
  </TableHeaderRow.Content>
);
const Command = ({ onExecute }: TableEditColumn.CommandProps) => (
  <PrimaryButton onClick={onExecute}>Remove</PrimaryButton>
);
const CommandColumnCell = (props: TableEditColumn.CellProps) => (
  <TableEditColumn.Cell {...props} />
);

const GroupCellContent = (props: TableGroupRowBase.ContentProps) => (
  <TableGroupRow.Content {...props} />
);

const GroupIconComponent = (props: TableGroupRowBase.IconProps) => {
  return <TableGroupRow.Icon {...props} />;
};

export default function ReportGrid({
  rows,
  title,
  columns,
  isLoading,
  isRemovable,
  summaryItems,
  handleNewSheet,
  columnBandsName,
  dateColumnsName,
  defaultGrouping,
  disabledSorting,
  hideExportPanel,
  disableFiltering,
  disabledGrouping,
  removeMethodName,
  DateTypeProvider,
  groupSummaryItems,
  disableDragAndDrop,
  CustomGroupIconComponent,
  customizeCellExport,
  reportHeaderTagLine,
  CustomCellComponent,
  headerTitleForSheet,
  currencyColumnsName,
  disableExportSummary,
  SummaryItemComponent,
  CurrencyTypeProvider,
  tableColumnExtensions,
  defaultGroupingColumn,
  disabledTableSelection,
  GroupSummaryItemComponent,
  summaryCalculator,
  customizeSummaryCell,
  groupCellContent,
  hiddenColumnNames,
  customizeFooter,
  pdfOrientation,
  showAdditionalExportOptions,
  valuesConverter,
  hideExportToCSV,
  noPagination,
  DateOnlyFormatter
}: Readonly<Props>) {
  const exporterRef = useRef<ExportRef>(null);
  const pageSizes = [5, 10, 15];
  const items = [...Array(100)].map((i: number) => i + 1);

  const [sorting, setSorting] = useState<Sorting[]>([]);
  const [selection, setSelection] = useState<(string | number)[]>([]);

  useEffect(() => setSelection([]), [rows]);

  const [grouping, setGrouping] = useState<Grouping[]>(
    defaultGroupingColumn ?? []
  );
  const [filters, setFilters] = useState<Filter[]>();

  const startExport = (options: unknown) => {
    exporterRef?.current?.exportGrid(options);
  };
  const onSave = async (workbook: Workbook) => {
    if (reportHeaderTagLine) {
      const mainSheet = workbook.getWorksheet('Main');
      mainSheet.getRow(3).font = { bold: true };
      const headerImageId = workbook.addImage({
        base64: base64HeaderImage,
        extension: 'png',
      });
      mainSheet.addImage(headerImageId, {
        tl: { col: 0.6, row: 0.95 },
        ext: { width: 200, height: 30 },
      });
      await handleNewSheet?.(workbook, customizeHeader, headerImageId);
    }

    workbook.xlsx.writeBuffer().then(async (buffer: BlobPart) => {
      
      saveAs(
        new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${title + dayjs().format('DDMMYYYY')}.xlsx`
      );
    });
  };

  const getBands = (
    tableColumns: Column[],
    bands?: TableBandHeaderBase.ColumnBands[]
  ) => {
    if (bands && tableColumns) {
      let currentBand: string;
      let start = 0;
      let end = 0;
      const bandList: {
        title: string;
        start: number;
        end: number;
      }[] = [];
      tableColumns.forEach((data, index) => {
        const band = bands.find(
          (el) =>
            el.children &&
            el?.children.some((child) => child.columnName === data.name)
        );

        const newBand = band?.title !== currentBand;
        const lastElement = index === columns.length - 1;

        if (!newBand) {
          end += 1;
        }

        if (newBand) {
          if (currentBand) {
            bandList.push({
              title: currentBand,
              start,
              end,
            });
          }
          currentBand = band?.title ?? '';
          start = index + 1;
          end = start;
        }

        if (lastElement) {
          bandList.push({
            title: currentBand,
            start,
            end,
          });
        }
      });
      return bandList;
    }
  };

  const customizeHeader = (worksheet: Worksheet) => {
    try {
      if (reportHeaderTagLine) {
        worksheet.mergeCells(1, 1, 1, 2);
        worksheet.mergeCells(1, 3, 1, 13);        
        worksheet.getRow(1).height = 55;
        worksheet.getRow(1).getCell(1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(1).getCell(3).font = {
          bold: true,
          size: 24,
        };
        worksheet.getRow(1).getCell(3).alignment = {
          vertical: 'middle',
          horizontal: 'left',
        };
        worksheet.getRow(1).getCell(3).value = headerTitleForSheet ?? 'Report';
        if (worksheet.name === 'Main') {
          worksheet.mergeCells(2, 1, 2, columns.length);
          worksheet.getRow(2).getCell(1).alignment = {
            vertical: 'middle',
            horizontal: 'left',
          };
          worksheet.getRow(2).getCell(1).font = { bold: true, size: 12 };
          worksheet.getRow(2).getCell(1).value = reportHeaderTagLine;
        }
      }
      const bands = getBands(columns, columnBandsName);
      if (bands)
        bands?.forEach((element) => {
          const { start, end, title: bandTitle } = element;
          worksheet.mergeCells(3, start, 3, end);
          worksheet.getRow(3).getCell(start).font = { bold: true, size: 12 };
          worksheet.getRow(3).getCell(start).value = bandTitle;
        });
    } catch (error) {
      logger?.error(error);
      appInsights?.trackException({ error: new Error(`${error}`), severityLevel: SeverityLevel.Error });
    }
  };

  const commitChanges = ({ deleted }: ChangeSet) => {
    if (deleted) removeMethodName?.([...deleted]);
  };
  
  const CustomExportMenu = useCallback(
    (props) => (
      <ExportMenu
        showAdditionalOptions={showAdditionalExportOptions}
        onPDFExport={() =>
          onPDFExport({
            headerTitleForSheet,
            reportHeaderTagLine,
            columns,
            rows,
            title,
            pdfOrientation,
            valuesConverter,
          })
        }
        exportToCSV={()=> exportToCSV(filters, rows, columns, title, valuesConverter, currencyColumnsName)}
        hideExportToCSV={hideExportToCSV}
        {...props}
      />
    ),
    [columns, currencyColumnsName, filters, headerTitleForSheet, hideExportToCSV, pdfOrientation, reportHeaderTagLine, rows, showAdditionalExportOptions, title, valuesConverter]
  );

  return (
    <>
      {isLoading ? (
        <LoaderContainer isTableDataLoading={isLoading}>
          {items.map((_key, index) => (
            <Loader
              key={index}
              variant="rectangular"
              width="100%"
              height={56}
            />
          ))}
        </LoaderContainer>
      ) : (
        rows &&
        rows?.length > 0 && (
          <CustomPaper id={rootContainerId} elevation={12}>
            <div id="selector"></div>
            <Grid rows={rows} columns={columns}>
              <FilteringState filters={filters} onFiltersChange={setFilters} />
              <SortingState
                sorting={sorting}
                onSortingChange={setSorting}
                columnSortingEnabled={!disabledSorting}
              />
              <IntegratedSorting />
              <SelectionState
                selection={selection}
                onSelectionChange={setSelection}
              />
              <EditingState onCommitChanges={commitChanges} />
              {defaultGrouping ? (
                <GroupingState
                  defaultGrouping={defaultGrouping}
                  defaultExpandedGroups={[]}
                  columnGroupingEnabled={!disabledGrouping}
                />
              ) : (
                <GroupingState
                  grouping={grouping}
                  onGroupingChange={setGrouping}
                  columnGroupingEnabled={!disabledGrouping}
                />
              )}
              <SummaryState
                totalItems={summaryItems}
                groupItems={groupSummaryItems}
              />
              <PagingState defaultCurrentPage={0} />
              <IntegratedGrouping />
              <IntegratedFiltering />
              <IntegratedSelection />
              <IntegratedSummary calculator={summaryCalculator} />
              <IntegratedPaging />

              {currencyColumnsName && CurrencyTypeProvider && (
                <CurrencyTypeProvider for={currencyColumnsName} />
              )}
              {dateColumnsName && DateTypeProvider && (
                <DateTypeProvider for={dateColumnsName} />
              )}
              {dateColumnsName && DateOnlyFormatter && (
                <DateOnlyFormatter for={dateColumnsName} />
              )}
              {!disableDragAndDrop && <DragDropProvider />}

              <Table
                tableComponent={TableComponent}
                columnExtensions={tableColumnExtensions}
                cellComponent={CustomCellComponent ?? CellComponent}
              />
              {!disableFiltering && <TableFilterRow showFilterSelector />}
              <TableSelection
                showSelectAll
                showSelectionColumn={!disabledTableSelection}
              />
              <TableEditColumn
                width={isRemovable ? 100 : 0}
                showDeleteCommand={isRemovable}
                commandComponent={Command}
                cellComponent={CommandColumnCell}
              />
              <TableGroupRow
                summaryItemComponent={GroupSummaryItemComponent}
                contentComponent={groupCellContent ?? GroupCellContent}
                iconComponent={CustomGroupIconComponent ?? GroupIconComponent}
              />
              <TableSummaryRow itemComponent={SummaryItemComponent} />
              <TableHeaderRow
                showSortingControls
                cellComponent={TableRowCellComponent}
                contentComponent={TableHeaderContent}
              />
              <TableColumnVisibility defaultHiddenColumnNames={hiddenColumnNames} />
              
              {columnBandsName && <TableBandHeader columnBands={columnBandsName} />}

              <Toolbar rootComponent={ToolbarRoot} />
              <GroupingPanel
                showSortingControls={!disableDragAndDrop}
                showGroupingControls={!disableDragAndDrop}
                containerComponent={(props) => {
                  return disableDragAndDrop ? (
                    <GroupingPanel.Container {...props}>
                      <span></span>
                    </GroupingPanel.Container>
                  ) : (
                    <GroupingPanel.Container {...props} />
                  );
                }}
                emptyMessageComponent={(props) => {
                  return (
                    <GroupingPanel.EmptyMessage
                      {...props}
                      getMessage={() =>
                        disableDragAndDrop ? '' : 'Drag and drop the filter.'
                      }
                    />
                  );
                }}
              />
              {!hideExportPanel && (
                <ExportPanel
                  startExport={startExport}
                  menuComponent={CustomExportMenu}
                  messages={customMessages}
                />
              )}
              {!noPagination && <PagingPanel pageSizes={pageSizes} />}              
            </Grid>
            <GridExporter
              rows={rows}
              ref={exporterRef}
              columns={columns}
              filters={filters}
              sorting={sorting}
              grouping={grouping}
              selection={selection}
              totalSummaryItems={
                summaryItems && disableExportSummary ? undefined : summaryItems
              }
              customizeHeader={customizeHeader}
              customizeFooter={customizeFooter}
              customizeCell={customizeCellExport}
              customizeSummaryCell={customizeSummaryCell}
              groupSummaryItems={groupSummaryItems}
              onSave={(options) => onSave(options)}
            />
          </CustomPaper>
        )
      )}
    </>
  );
}

const CustomPaper = styled(Paper)`
  .Pager-pager {
    justify-content: start !important;
    flex-direction: row-reverse !important;
  }
`;
const TableHeaderRowCell = styled(TableHeaderRow.Cell)`
  padding: 0;
`;

const DesktopTableContainer = styled(Container)`
  width: 100%;
  padding: ${({ theme }) => `${theme.padding.l} 0 0 ${theme.padding.xl}`};
  padding-left: 0;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-top: ${({ theme }) => ` ${theme.padding.m}`};
  }
`;

const LoaderContainer = styled(DesktopTableContainer)<{
  isTableDataLoading: boolean;
}>`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: ${({ theme, isTableDataLoading }) =>
      `${theme.padding.m} ${isTableDataLoading ? '20px' : 0}`};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: ${({ theme, isTableDataLoading }) =>
      `${theme.padding.m} ${isTableDataLoading ? '15px' : 0}`};
  }
`;

const Loader = styled(Skeleton)`
  margin-bottom: ${({ theme }) => theme.margin.s};
`;

const CustomToolbarRoot = styled(Toolbar.Root)`
  background: ${({ theme }) => theme.palette.reports.header};
`;
